import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

const state = {};

const getters = {};

const mutations = {};

const actions = {
    salvarLogErro(context, log) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("logErro/access/salvar", log).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};