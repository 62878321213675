import { createStore } from "vuex";

import configuracoes from "./configuracoes.module";
import auth from "./auth.module";
import pacientes from "./pacientes.module";
import agendamentos from "./agendamentos.module";
import convenios from "./convenios.module";
import servicosexternos from "./servicosexternos.module";
import profissoes from "./profissoes.module";
import unidades from "./unidades.module";
import staffMedicosCategorias from "./staffMedicosCategorias.module";
import staffMedicos from "./staffMedicos.module";
import contatosExternos from "./contatosExternos.module";
import tiposAtendimento from "./tiposAtendimento.module";
import emitentes from "./emitentes.module";
import servicos from "./servicos.module";
import procedimentos from "./procedimentos.module";
import produtos from "./produtos.module";
import security from "./security.module";
import especialidades from "./especialidades.module";
import locaisatendimento from "./locaisatendimento.module";
import classificacaoAtendimentos from "./classificacaoAtendimentos.module";
import classificacaoGuias from "./classificacaoGuias.module";
import cid from "./cid.module";
import cfm from "./cfm.module";
import equipesMedicas from "./equipesmedicas.module";
import atendimentos from "./atendimentos.module";
import globais from "./globais.module";
import medicamentos from "./medicamentos.module";
import textos from "./textos.module";
import textosEmail from "./textosEmail.module";
import turnos from "./turnos.module";
import categoriasTextos from "./categoriasTextos.module";
import empresas from "./empresas.module";
import portadores from "./portadores.module";
import lgpd from "./lgpd.module";
import produtosUnidades from "./produtosunidades.module";
import locaisAtendimentoCategorias from "./locaisatendimentocategorias.module";
import tabelasPrecos from "./tabelasprecos.module";
import agendamentosFilaLaudo from "./agendamentosFilaLaudo.module";
import moedasEstrangeiras from "./moedasestrangeiras.module";
import frases from "./frases.module";
import functions from "./functions.module";
import chamadatv from "./chamadatv.module";
import filasAtendimento from "./filasatendimento.module";
import produtosEstoqueLocais from "./produtosEstoqueLocais.module";
import locaisStatus from "./locaisStatus.module";
import centrosResponsabilidade from "./centrosResponsabilidade.module";
import produtosCategorias from "./produtosCategorias.module";
import produtosTempoAtendimento from "./produtosTempoAtendimento.module";
import produtosMarcas from "./produtosMarcas.module";
import cnae from "./cnae.module";
import bi from "./bi.module";
import repasse from "./repasse.module";
import logerro from "./logerro.module";
import agendamentosRetorno from "./agendamentosRetorno.module";
import solicitacoesProcedimento from "./solicitacoesProcedimento.module";
import contasPaciente from "./contasPaciente.module";
import contasPacientePendencia from "./contasPacientePendencia.module";
import tiposMovimento from "./tiposMovimento.module";
import tiposEstoque from "./tiposEstoque.module";
import unidadesMedida from "./unidadesMedida.module";
import fichasArquetipos from "./fichasArquetipos.module";
import pacientesArquetipo from "./pacientesArquetipo.module";
import tiposPendencia from "./tiposPendencia.module";
import tiposOcorrencia from "./tiposOcorrencia.module";
import talklogos from "./talklogos.module";
import motivosAusencia from "./motivosAusencia.module";

export default createStore({
    modules: {
        configuracoes, 
        auth, 
        pacientes, 
        agendamentos, 
        convenios, 
        servicosexternos, 
        profissoes, 
        unidades, 
        staffMedicosCategorias, 
        staffMedicos, 
        contatosExternos, 
        tiposAtendimento, 
        emitentes, 
        servicos, 
        procedimentos, 
        produtos, 
        security, 
        especialidades, 
        locaisatendimento, 
        classificacaoAtendimentos, 
        classificacaoGuias, 
        cid, 
        cfm, 
        equipesMedicas, 
        atendimentos, 
        globais, 
        medicamentos, 
        textos, 
        textosEmail, 
        turnos, 
        categoriasTextos, 
        empresas, 
        portadores, 
        lgpd, 
        produtosUnidades, 
        locaisAtendimentoCategorias, 
        tabelasPrecos, 
        agendamentosFilaLaudo, 
        moedasEstrangeiras, 
        frases, 
        functions, 
        chamadatv, 
        produtosEstoqueLocais, 
        locaisStatus, 
        filasAtendimento, 
        centrosResponsabilidade, 
        produtosCategorias, 
        produtosTempoAtendimento, 
        produtosMarcas, 
        cnae, 
        bi, 
        repasse, 
        contasPaciente, 
        contasPacientePendencia, 
        logerro, 
        agendamentosRetorno, 
        solicitacoesProcedimento, 
        tiposMovimento, 
        tiposEstoque, 
        unidadesMedida, 
        fichasArquetipos, 
        pacientesArquetipo, 
        tiposPendencia, 
        talklogos, 
        motivosAusencia, 
        tiposOcorrencia
    }
});