import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

const state = {};

const getters = {};

const mutations = {};

const actions = {
    getTiposAtendimento() {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.get("tiposAtendimento/access/listar").then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getTipoAtendimento(context, idAtendimentoTipo) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("tiposAtendimento/access/obter", { ID_ATENDIMENTO_TIPO: idAtendimentoTipo }).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getAtendimentosTiposBox(context, filtros) {
        filtros.componente = "SearchBoxAtendimentoTipo";
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("tiposAtendimento/access/listaBox", filtros).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};