const state = {
    idPacienteTotem: "", 
    preenchePacienteTotem: false, 
    appOnline: false, 
    appFabricante: "Micrologos", 
    appNome: "Clinic", 
    appVersao: process.env.VUE_APP_VERSION || "0", 
    appModoTotem: false, 
    appTotemUnidade: (window.localStorage.getItem("appTotemUnidade") ? JSON.parse(window.localStorage.getItem("appTotemUnidade")) : {}), 
    appTotemLocal: (window.localStorage.getItem("appTotemLocal") ? JSON.parse(window.localStorage.getItem("appTotemLocal")) : {}), 
    appInfoSuperior: "", 
    appPrimeiraEntrada: true, 
    appUsuariosOnline: []
};

const getters = {
    idPacienteTotem(state) {
        return state.idPacienteTotem;
    }, 
    preenchePacienteTotem(state) {
        return state.preenchePacienteTotem;
    }, 
    appOnline(state) {
        return state.appOnline;
    }, 
    appFabricante(state) {
        return state.appFabricante;
    }, 
    appNome(state) {
        return state.appNome;
    }, 
    appVersao(state) {
        return state.appVersao;
    }, 
    appModoTotem(state) {
        return state.appModoTotem;
    }, 
    appTotemUnidade(state) {
        return state.appTotemUnidade;
    }, 
    appTotemLocal(state) {
        return state.appTotemLocal;
    }, 
    appInfoSuperior(state) {
        return state.appInfoSuperior;
    }, 
    appPrimeiraEntrada(state) {
        return state.appPrimeiraEntrada;
    }, 
    appUsuariosOnline(state) {
        return state.appUsuariosOnline;
    }
};

const mutations = {
    upadateIdPacienteTotem(store, idPacienteTotem) {
        store.idPacienteTotem = idPacienteTotem;
    }, 
    upadatePreenchePacienteTotem(store, preenchePacienteTotem) {
        store.preenchePacienteTotem = preenchePacienteTotem;
    }, 
    updateAppOnline(store, appOnline) {
        store.appOnline = appOnline;
    }, 
    updateAppModoTotem(store, appModoTotem) {
        store.appModoTotem = appModoTotem;
    }, 
    updateAppTotemUnidade(store, appTotemUnidade) {
        window.localStorage.setItem("appTotemUnidade", JSON.stringify(appTotemUnidade));
        store.appTotemUnidade = appTotemUnidade;
    }, 
    updateAppTotemLocal(store, appTotemLocal) {
        window.localStorage.setItem("appTotemLocal", JSON.stringify(appTotemLocal));
        store.appTotemLocal = appTotemLocal;
    }, 
    updateAppInfoSuperior(store, appInfoSuperior) {
        store.appInfoSuperior = appInfoSuperior;
    }, 
    updateAppPrimeiraEntrada(store, appPrimeiraEntrada) {
        store.appPrimeiraEntrada = appPrimeiraEntrada;
    }, 
    updateAppUsuariosOnline(store, appUsuariosOnline) {
        store.appUsuariosOnline = appUsuariosOnline;
    }, 
    updateAppUsuarioOnline(store, usuario) {
        if(usuario.online) {
            store.appUsuariosOnline.push(usuario);
        } else {
            const index = store.appUsuariosOnline.findIndex((u) => u.idEmitenteMLUsuario === usuario.idEmitenteMLUsuario);

            if (index >= 0) {
                store.appUsuariosOnline.splice(index, 1);
            }
        }
    }
};

const actions = {
    upadateIdPacienteTotem({ commit }, idPacienteTotem) {
        commit('upadateIdPacienteTotem', idPacienteTotem);
    },
    upadatePreenchePacienteTotem({ commit }, preenchePacienteTotem) {
        commit('upadatePreenchePacienteTotem', preenchePacienteTotem);
    },
    updateAppOnline({ commit }, appOnline) {
        commit('updateAppOnline', appOnline);
    },
    updateAppModoTotem({ commit }, appModoTotem) {
        commit('updateAppModoTotem', appModoTotem);
    },
    updateAppTotemUnidade({ commit }, appTotemUnidade) {
        commit('updateAppTotemUnidade', appTotemUnidade);
    },
    updateAppTotemLocal({ commit }, appTotemLocal) {
        commit('updateAppTotemLocal', appTotemLocal);
    },
    updateAppInfoSuperior({ commit }, appInfoSuperior) {
        commit('updateAppInfoSuperior', appInfoSuperior);
    },
    updateAppPrimeiraEntrada({ commit }, appPrimeiraEntrada) {
        commit('updateAppPrimeiraEntrada', appPrimeiraEntrada);
    }, 
    async "SOCKET_usuariosOnline" ({ commit }, usuarios) {
        commit('updateAppUsuariosOnline', usuarios);
    }, 
    async "SOCKET_usuarioOnline" ({ commit }, usuario) {
        commit('updateAppUsuarioOnline', usuario);
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};