import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

const state = {};

const getters = {};

const mutations = {};

const actions = {
    getTextos(context, dados) {
        if((!dados.pagina) || (!dados.quantidadePorPagina)) {
            dados.pagina = 1;
            dados.quantidadePorPagina = 25;
        }
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("textos/access/listar", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getTextosBox(context, filtros) {
        filtros.componente = "SearchBoxTextos";
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("textos/access/listaBox", filtros).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    obterModeloComMacros(context, dados) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("modelosLaudos/access/obterModeloComMacros", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getTexto(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("textos/access/obter", {ID_TEXTO_LAUDO: dados.id, componente: dados.componente}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    deleteTexto(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("textos/access/excluir", {ID_TEXTO_LAUDO: dados.id, componente: dados.componente}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    postTexto(context, texto) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                let textoNovo = JSON.parse(JSON.stringify(texto));
                if(textoNovo.TEXTO) {
                    textoNovo.TEXTO = btoa(unescape(encodeURIComponent(textoNovo.TEXTO)));
                }
                ApiService.setHeader();
                ApiService.post("textos/access/salvar", textoNovo).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getTextosWeb() {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.get("modelosLaudos/access/listaWeb").then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getMacroTexto() {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.get("atendimentosFichasDados/access/macrosAtendimentoTipo").then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    listarTextosLocais() {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.get("textos/access/listarTextosLocais").then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    listarTextosMedicamentos(context, idTexto) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("textos/access/listarTextosMedicamentos", {ID_TEXTO_LAUDO:idTexto}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    listarCategoriasNuvem() {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.get("textos/access/listarCategoriasNuvem").then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    importarTextoLocal(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("textos/access/importarTextoLocal", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};