import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  getAgendamentosRetorno(context, dados) {
    return new Promise(resolve => {
        if(JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.post("agendamentosRetorno/access/listar", dados).then(({ data }) => {
                resolve(data);
            });
        }
    });
  }, 
  salvarAgendamentoRetorno(context, dados) {
    return new Promise(resolve => {
        if(JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.post("agendamentosRetorno/access/salvar", dados).then(({ data }) => {
                resolve(data);
            });
        }
    });
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};