<template>
  <div class="modal fade" :class="{'show': showModal}" tabindex="-1" :style="{'display': (showModal ? 'block' : 'none')}">
    <!--begin::Modal dialog-->
    <transition enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut" mode="out-in" @after-leave="onAfterLeave" @after-enter="onAfterEnter">
      <div class="modal-dialog" :class="{'modal-fullscreen': fullscreen, 'mw-650px': !fullscreen}" style="z-index: 1051;" v-show="showModalInside">
        <!--begin::Modal content-->
        <div class="modal-content">
          <!--begin::Modal header-->
          <div class="modal-header pb-0 border-0 justify-content-end">
            <!--begin::Close-->
            <div class="btn btn-sm btn-icon btn-active-color-primary" @click="fecharModalInterno">
              <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
              <span class="svg-icon svg-icon-1">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor" />
                  <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor" />
                </svg>
              </span>
              <!--end::Svg Icon-->
            </div>
            <!--end::Close-->
          </div>
          <!--begin::Modal header-->
          <!--begin::Modal body-->
          <div class="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15" style="padding-bottom: 22.75px !important; margin: 0 !important;">
            <slot></slot>
          </div>
          <!--end::Modal body-->
        </div>
        <!--end::Modal content-->
      </div>
    </transition>
    <!--end::Modal dialog-->
    <div v-if="showModal" class="modal-backdrop fade show" @click="fecharModalInterno"></div>
  </div>
</template>

<script>
export default {
  name: "ModalBase",
  data() {
    return {
      showModal: false,
      showModalInside: false
    };
  },
  props: ["fullscreen"],
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
      this.showModalInside = !this.showModalInside;
      this.$emit("toggleModal", this.showModal);
    },
    onAfterLeave() {
      this.showModal = false;
      this.$emit("toggleModal", this.showModal);
    }, 
    onAfterEnter() {
      this.showModal = true;
      this.$emit("afterToggleEnter", this.showModal);
    }, 
    fecharModalInterno() {
      this.showModalInside = false;
    }
  }
}  
</script>

<style scoped>

</style>