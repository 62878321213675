import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

const state = {
    entrarAtendendo: false, 
    imagensIncluindo: [], 
    cidIncluindo: [], 
    medicamentosIncluindo: [], 
    textosIncluindo: [], 
    agendasRetornoIncluindo: [], 
    historicosIncluindo: [], 
    solicitacoesProcedimentosIncluindo: [], 
    arquetiposIncluindo: [], 
    idLogLaudoExibir: "", 
    imprimirLaudoDireto: false, 
    refazTextoLaudo: false, 
    salvarTextoDiretamente: false, 
    filtrarDiretamenteAtendimento: "", 
    enviandoImagensPepTimeline: false
};

const getters = {
    entrarAtendendo(state) {
        return state.entrarAtendendo;
    },
    imagensIncluindo(state) {
        return state.imagensIncluindo;
    },
    cidIncluindo(state) {
        return state.cidIncluindo;
    },
    medicamentosIncluindo(state) {
        return state.medicamentosIncluindo;
    },
    textosIncluindo(state) {
        return state.textosIncluindo;
    },
    agendasRetornoIncluindo(state) {
        return state.agendasRetornoIncluindo;
    },
    historicosIncluindo(state) {
        return state.historicosIncluindo;
    },
    solicitacoesProcedimentosIncluindo(state) {
        return state.solicitacoesProcedimentosIncluindo;
    },
    arquetiposIncluindo(state) {
        return state.arquetiposIncluindo;
    },
    idLogLaudoExibir(state) {
        return state.idLogLaudoExibir;
    },
    imprimirLaudoDireto(state) {
        return state.imprimirLaudoDireto;
    },
    refazTextoLaudo(state) {
        return state.refazTextoLaudo;
    },
    salvarTextoDiretamente(state) {
        return state.salvarTextoDiretamente;
    },
    filtrarDiretamenteAtendimento(state) {
        return state.filtrarDiretamenteAtendimento;
    }, 
    enviandoImagensPepTimeline(state) {
        return state.enviandoImagensPepTimeline;
    }
};

const mutations = {
    updateEntrarAtendendo(store, entrarAtendendo) {
        store.entrarAtendendo = entrarAtendendo;
    },
    updateImagensIncluindo(store, imagensIncluindo) {
        store.imagensIncluindo = imagensIncluindo;
    },
    updateCidIncluindo(store, cidIncluindo) {
        store.cidIncluindo = cidIncluindo;
    },
    updateMedicamentosIncluindo(store, medicamentosIncluindo) {
        store.medicamentosIncluindo = medicamentosIncluindo;
    },
    updateTextosIncluindo(store, textosIncluindo) {
        store.textosIncluindo = textosIncluindo;
    },
    updateAgendasRetornoIncluindo(store, agendasRetornoIncluindo) {
        store.agendasRetornoIncluindo = agendasRetornoIncluindo;
    },
    updateHistoricosIncluindo(store, historicosIncluindo) {
        store.historicosIncluindo = historicosIncluindo;
    },
    updateSolicitacoesProcedimentosIncluindo(store, solicitacoesProcedimentosIncluindo) {
        store.solicitacoesProcedimentosIncluindo = solicitacoesProcedimentosIncluindo;
    },
    updateArquetiposIncluindo(store, arquetiposIncluindo) {
        store.arquetiposIncluindo = arquetiposIncluindo;
    },
    updateIdLogLaudoExibir(store, idLogLaudoExibir) {
        store.idLogLaudoExibir = idLogLaudoExibir;
    },
    updateImprimirLaudoDireto(store, imprimirLaudoDireto) {
        store.imprimirLaudoDireto = imprimirLaudoDireto;
    },
    updateRefazTextoLaudo(store, refazTextoLaudo) {
        store.refazTextoLaudo = refazTextoLaudo;
    },
    updateSalvarTextoDiretamente(store, salvarTextoDiretamente) {
        store.salvarTextoDiretamente = salvarTextoDiretamente;
    },
    updateFiltrarDiretamenteAtendimento(store, filtrarDiretamenteAtendimento) {
        store.filtrarDiretamenteAtendimento = filtrarDiretamenteAtendimento;
    }, 
    updateEnviandoImagensPepTimeline(store, enviandoImagensPepTimeline) {
        store.enviandoImagensPepTimeline = enviandoImagensPepTimeline;
    }
};

const actions = {
    atualizarEntrarAtendendo(context, entrarAtendendo) {
        context.commit("updateEntrarAtendendo", entrarAtendendo);
    },
    atualizarImagensIncluindo(context, imagensIncluindo) {
        context.commit("updateImagensIncluindo", imagensIncluindo);
    },
    atualizarCidIncluindo(context, cidIncluindo) {
        context.commit("updateCidIncluindo", cidIncluindo);
    },
    atualizarMedicamentosIncluindo(context, medicamentosIncluindo) {
        context.commit("updateMedicamentosIncluindo", medicamentosIncluindo);
    },
    atualizarTextosIncluindo(context, textosIncluindo) {
        context.commit("updateTextosIncluindo", textosIncluindo);
    },
    atualizarAgendasRetornoIncluindo(context, agendasRetornoIncluindo) {
        context.commit("updateAgendasRetornoIncluindo", agendasRetornoIncluindo);
    },
    atualizarHistoricosIncluindo(context, historicosIncluindo) {
        context.commit("updateHistoricosIncluindo", historicosIncluindo);
    },
    atualizarSolicitacoesProcedimentosIncluindo(context, solicitacoesProcedimentosIncluindo) {
        context.commit("updateSolicitacoesProcedimentosIncluindo", solicitacoesProcedimentosIncluindo);
    },
    atualizarArquetiposIncluindo(context, arquetiposIncluindo) {
        context.commit("updateArquetiposIncluindo", arquetiposIncluindo);
    },
    atualizarIdLogLaudoExibir(context, idLogLaudoExibir) {
        context.commit("updateIdLogLaudoExibir", idLogLaudoExibir);
    },
    atualizarImprimirLaudoDireto(context, imprimirLaudoDireto) {
        context.commit("updateImprimirLaudoDireto", imprimirLaudoDireto);
    },
    atualizarRefazTextoLaudo(context, refazTextoLaudo) {
        context.commit("updateRefazTextoLaudo", refazTextoLaudo);
    },
    atualizarSalvarTextoDiretamente(context, salvarTextoDiretamente) {
        context.commit("updateSalvarTextoDiretamente", salvarTextoDiretamente);
    },
    atualizarFiltrarDiretamenteAtendimento(context, filtrarDiretamenteAtendimento) {
        context.commit("updateFiltrarDiretamenteAtendimento", filtrarDiretamenteAtendimento);
    },
    atualizarEnviandoImagensPepTimeline(context, enviandoImagensPepTimeline) {
        context.commit("updateEnviandoImagensPepTimeline", enviandoImagensPepTimeline);
    },
    getPEPEstatisticas(context, filtros) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/listaPEPEstatisticas", filtros).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getPEPDatas(context, filtros) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/listaPEPDatas", filtros).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getPEP(context, filtros) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/listaPEP", filtros).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getAtendimentoDetalhes(context, dados) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/listarAtendimentoDetalhesDados", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getAtendimentosDetalhes(context, dados) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/listarAtendimentosDetalhesDados", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getFichasDadosMedico(context, dados) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/listarFichasDadosMedico", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getAtendimentosTipoItens(context, dados) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/listarAtendimentoTipoItens", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getTitulosFrases(context, dados) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/listarTitulosFrases", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getPDF(context, token) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/obterPDF", { TOKEN: token }).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getUltimoDocumentoProduto(context, idAgendaExame) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/obterUltimoDocumentoProduto", { ID_AGENDA_EXAME: idAgendaExame }).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getDocumento(context, idDiversos) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/obterDocumento", { ID_DIVERSOS: idDiversos }).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getCIDMaisUsados() {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.get("atendimentos/access/listar5MaisUsadosPorMedico").then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getAtendimentosHoje(context, dados) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/obterAtendimentosHoje", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getCabecalhoRodape(context, dados) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/obterCabecalhoRodape", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    iniciarAtendimentoServer(context, dados) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/iniciarAtendimento", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    salvarAtendimento(context, detalhes) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/salvarAtendimento", detalhes).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }, 
    salvarAtendimentoDetalhe(context, detalhes) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/salvarAtendimentoDetalhe", detalhes).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }, 
    atualizarDiversos(context, diverso) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/atualizarDiversos", diverso).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    excluirDiversos(context, idDiversos) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/excluirDiversos", { ID_DIVERSOS: idDiversos }).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getHistoricos(context, idPaciente) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/buscarHistoricos", { ID_PACIENTE: idPaciente}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    excluirHistorico(context, dados) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/excluirHistorico", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    atualizarImagemTitulo(context, dados) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/atualizarImagemTitulo", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    salvarMedicamento(context, dados) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/salvarMedicamento", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    removerMedicamento(context, idAtendimentoMedicamento) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/removerMedicamento", {ID_ATENDIMENTOS_MEDICAMENTOS: idAtendimentoMedicamento}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    buscarArquetipos(context, idAgenda) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/buscarArquetipos", {ID_AGENDA: idAgenda}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    listarRetornosAtendimento(context, idAtendimento) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/listarRetornosAtendimento", {ID_ATENDIMENTO: idAtendimento}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getDiversos(context, idDiversos) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/obterDiversos", {ID_DIVERSOS: idDiversos}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    salvarDiversos(context, dados) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/salvarDiversos", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    buscarComplementos(context, idAgendaExame) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/buscarComplementos", {ID_AGENDA_EXAME: idAgendaExame}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    salvarComplemento(context, dados) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/salvarComplemento", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    excluirComplemento(context, dados) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/excluirComplemento", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    listarPendencias(context, idDiversos) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/listarPendencias", {ID_DIVERSOS: idDiversos}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    obterLaudoPaciente(context, dados) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/obterLaudoPaciente", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    liberarLaudoPaciente(context, dados) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/liberarLaudoPaciente", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    salvarSolicitarRevisao(context, dados) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/salvarSolicitarRevisao", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    revisarModelo(context, dados) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("modelosLaudos/access/revisarModelo", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    validarSenhaCertificado(context, senha) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/validarSenhaCertificado", {SENHA: senha}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    associaPDFLaudoLiberado(context, dados) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/associaPDFLaudoLiberado", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    downloadLaudoPDF(context, idPacienteLaudo) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/downloadLaudoPDF", {ID_PACIENTE_LAUDO: idPacienteLaudo}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getLogsLaudo(context, idDiversos) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/listarLogLaudo", {ID_DIVERSOS: idDiversos}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getLogLaudo(context, idLaudoLog) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/obterLogLaudo", {ID_LAUDO_LOG: idLaudoLog}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    listarAtendimentosSistemas(context, idPaciente) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/listarSistemas", {ID_PACIENTE: idPaciente}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    excluirSistemaPadrao(context, idAtendimentoSistema) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/excluirSistemaPadrao", {ID_ATENDIMENTO_SISTEMA: idAtendimentoSistema}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    salvarSistemaPadrao(context, dados) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/salvarSistemaPadrao", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    listarMarcacoes(context, idPaciente) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/listarMarcacoes", {ID_PACIENTE: idPaciente}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    removerMarcacao(context, idAtendimentoArea) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/removerMarcacao", {ID_ATENDIMENTO_AREA: idAtendimentoArea}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    salvarMarcacao(context, dados) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/salvarMarcacao", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }, 
    alterarAreaItem(context, dados) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/alterarAreaItem", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }, 
    auditarItemAtendimento(context, dados) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/auditarItemAtendimento", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }, 
    removerAuditoriaItemAtendimento(context, idAtendimentoAuditoria) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/removerAuditoriaItemAtendimento", {ID_ATENDIMENTO_AUDITORIA: idAtendimentoAuditoria}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }, 
    getDetalhesAtendimento(context, idAtendimento) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/listarDetalhesAtendimento", {ID_ATENDIMENTO: idAtendimento}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }, 
    getPosologiasBox(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/listaBoxPosologias", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }, 
    verificarAlergiaFarmaco(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/verificarAlergiaFarmaco", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }, 
    buscarFichaDadosFavoritos(context, idFichaDadosCampo) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/buscarFichaDadosFavoritos", {ID_FICHA_DADOS_CAMPO: idFichaDadosCampo}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }, 
    excluirFichaDadoFavorito(context, idFichaDadosCampoFavorito) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/excluirFichaDadoFavorito", {ID_FICHA_DADOS_CAMPO_FAVORITO: idFichaDadosCampoFavorito}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }, 
    salvarFichaDadoFavorito(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/salvarFichaDadoFavorito", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }, 
    obterJitsiToken(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/getJitsiToken", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }, 
    getBloqueioLaudo(context, idDiversos) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/obterBloqueioLaudo", {ID_DIVERSOS: idDiversos}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }, 
    desbloquearLaudo(context, idDiversos) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/desbloquearLaudo", {ID_DIVERSOS: idDiversos}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    salvarVideoTeleatendimento(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("teleatendimentos/access/salvarVideoTeleatendimento", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }, 
    liberarImagemPaciente(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("atendimentos/access/liberarImagemPaciente", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};