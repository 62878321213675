<template>
  <div class="footer py-4 d-flex flex-lg-column" id="kt_footer" style="padding: 0 !important;" :style="{'display': appModoTotem ? 'none !important' : ''}">
    <!--begin::Container-->
    <div class="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
      <!--begin::Copyright-->
      <div class="text-dark order-2 order-md-1">
        <span class="text-muted fw-semibold me-1">{{ ano }}© {{ appNome }} Versão {{ appVersao }}</span>
        <a href="javascript:;" class="text-gray-800 text-hover-primary">by {{ appFabricante }} 40 anos</a>
      </div>
      <!--end::Copyright-->
      <!--begin::Menu-->
      <ul class="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
        <li class="menu-item">
          <a href="javascript:;" class="menu-link px-2">Sobre</a>
        </li>
        <li class="menu-item">
          <a href="javascript:;" class="menu-link px-2">Suporte</a>
        </li>
        <li class="menu-item">
          <a href="javascript:;" class="menu-link px-2">Adquirir módulos</a>
        </li>
      </ul>
      <!--end::Menu-->
    </div>
    <!--end::Container-->
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from 'vuex';

export default {
  name: "FooterBottom", 
  data() {
    return {
      ano: "2023"
    };
  }, 
  mounted() {
    this.ano = moment().format("YYYY");
  },
  computed: {
    ...mapGetters([
      "appFabricante",
      "appNome",
      "appVersao",
      "appModoTotem"
    ])
  }
}
</script>

<style scoped>
  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1000;
  }
</style>