import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

const state = {};

const getters = {};

const mutations = {};

const actions = {
    getContatosExternos(context, dados) {
        if ((!dados.pagina) || (!dados.quantidadePorPagina)) {
            dados.pagina = 1;
            dados.quantidadePorPagina = 25;
        }
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("contatosExternos/access/listar", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getContatosExternosBox(context, filtros) {
        filtros.componente = "SearchBoxContatoExterno";
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("contatosExternos/access/listaBox", filtros).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getContatoExterno(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("contatosExternos/access/obter", { ID_CONTATO_EXTERNO: dados.id, componente: dados.componente }).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    deleteContatoExterno(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("contatosExternos/access/excluir", { ID_CONTATO_EXTERNO: dados.id, componente: dados.componente }).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    postContatoExterno(context, contatoExterno) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("contatosExternos/access/salvar", contatoExterno).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};