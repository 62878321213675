import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

const state = {};

const getters = {};

const mutations = {};

const actions = {
    getProdutosEstoqueLocaisBox(context, filtros) {
        filtros.componente = "SearchBoxCentrosResponsabilidade";
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("produtosEstoqueLocais/access/listaBox", filtros).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};