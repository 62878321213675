import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

const state = {};

const getters = {};

const mutations = {};

const actions = {
    getTextosEmail(context, dados) {
        if((!dados.pagina) || (!dados.quantidadePorPagina)) {
            dados.pagina = 1;
            dados.quantidadePorPagina = 25;
        }
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("textosEmail/access/listar", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getTextoEmailsBox(context, dados) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("textosEmail/access/listaBox", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getTextoEmail(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("textosEmail/access/obter", {ID_TEXTO_HTML: dados.id, componente: dados.componente}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    deleteTextoEmail(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("textosEmail/access/excluir", {ID_TEXTO_HTML: dados.id, componente: dados.componente}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    postTextoEmail(context, textoEmail) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("textosEmail/access/salvar", textoEmail).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};