<template>
  <a href="javascript:;">
    <img v-if="fgSistema == 'CLINIC'" alt="Logo" src="/assets/custom/logos/icone-clinic.png" class="h-40px h-lg-40px" />
    <img v-if="fgSistema == 'QUALITY'" alt="Logo" src="/assets/custom/logos/icone-quality.png" class="h-40px h-lg-40px" />
  </a>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "LogoSistema",
  computed: {
    ...mapGetters(['fgSistema'])
  }
}

</script>

<style scoped>

</style>