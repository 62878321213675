// Global Functions

window.isBase64 = (str) => {
    if((typeof str === 'string') || (str instanceof String)) {
        if((str == "") || (str.trim() == "")) {
            return false;
        }

        try {
            return (btoa(atob(str)) == str);
        } catch (err) {
            return false;
        }
    } else {
        return false;
    }
};

window.b64EncodeUnicode = (texto) => {
    return btoa(unescape(encodeURIComponent(texto)));
};

window.b64DecodeUnicode = (texto) => {
    if(window.isBase64(texto)) {
        try {
            return decodeURIComponent(atob(texto).split('').map((c) => {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
        } catch (error) {
            try {
                return atob(texto);
            } catch (error) {
                return "";
            }
        }
    } else {
        return texto;
    }
};

window.validateEmail = (email) => {
    return String(email).toLowerCase().match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

import { createApp } from 'vue';
import { createI18n } from "vue-i18n";
import App from './App.vue';
import router from './router';
import store from "@/core/services/store";
import VueSocketIO from "vue-3-socket.io";
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import Maska from "maska";

import VueSignaturePad from 'vue-signature-pad';

import VueConfetti from 'vue-confetti';

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import CKEditor from '@mayasabha/ckeditor4-vue3';

import 'animate.css';

import VCalendar from 'v-calendar';
import 'v-calendar/style.css';

import { v4 as uuidv4 } from 'uuid';

import mitt from 'mitt';
const emitter = mitt();

import ptBR from "./locales/ptBR.json";
import en from "./locales/en.json";
import es from "./locales/es.json";
import './registerServiceWorker'

const i18n = createI18n({
    locale: "ptBR",
    fallbackLocale: "ptBR",
    messages: { ptBR, en, es }
});

var url = window.location.href;

var token = /token=([^&]+)/.exec(url);
if (token) {
    token = token[1];
}

var sistema = /sistema=([^&]+)/.exec(url);
if (sistema) {
    sistema = sistema[1];
}

if (token) {
    JwtService.saveToken(token);
} else {
    token = JwtService.getToken();
}

if (sistema) {
    JwtService.saveSistema(sistema);
} else {
    sistema = JwtService.getSistema();

    if (!sistema) {
        sistema = "CLINIC";
        JwtService.saveSistema(sistema);
    }
}

if (!token) {
    window.location.href = "https://auth.micrologos.com.br/#/login/" + sistema;
}

const deviceName = JwtService.criarObterGenerico("deviceName", "DEVICE_" + uuidv4());

const app = createApp(App);
app.use(i18n);

ApiService.init(app);

app.use(new VueSocketIO({
    withCredentials: true, 
    reconnection: true, 
    debug: true, 
    connection: "https://backendio.micrologos.com.br", 
    // connection: "http://localhost:3000", 
    vuex: {
        store, 
        actionPrefix: "SOCKET_", 
        mutationPrefix: "SOCKET_"
    }, 
    options: {
        query: {
            token, 
            deviceName
        }
    }
}))

app.use(router);
app.use(store);
app.use(VueSweetalert2);
app.use(Maska);
app.use(VueSignaturePad);
app.use(VueConfetti);
app.use(Toast, {});
app.use(CKEditor);
app.use(VCalendar, {});
app.config.globalProperties.emitter = emitter;

app.mount('#app');