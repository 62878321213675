import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

const state = {};

const getters = {};

const mutations = {};

const actions = {
    getEspecialidadesBox(context, filtros) {
        filtros.componente = "SearchBoxEspecialidade";
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("especialidades/access/listaBox", filtros).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getEspecialidadesMedicosBox(context, filtros) {
        filtros.componente = "SearchBoxEspecialidadeMedico";
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("especialidades/access/listaBoxEspecMedicos", filtros).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getEspecialidade(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("especialidades/access/obter", { ID_ESPECIALIDADE: dados.id, componente: dados.componente }).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getEspecialidadesTodas() {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.get("especialidades/access/listaLocal").then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },

};

export default {
    state,
    actions,
    mutations,
    getters
};