import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

const state = {};

const getters = {};

const mutations = {};

const actions = {
    getClassificacoesGuia(context, dados) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("classificacaoGuias/access/listarPorConvenioClassifAtend", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getClassificacoesGuiaBox(context, filtros) {
        filtros.componente = "SearchBoxClassificacoesGuia";
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("classificacaoGuias/access/listaBox", filtros).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getClassificacaoGuias(context, dados) {
        if((!dados.pagina) || (!dados.quantidadePorPagina)) {
            dados.pagina = 1;
            dados.quantidadePorPagina = 25;
        }
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("classificacaoGuias/access/listar", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getClassificacaoGuia(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("classificacaoGuias/access/obter", {ID_CLASSIFICA_GUIA: dados.id, componente: dados.componente}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getClassificacaoGuiaPlanos(context, idRegistro) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("classificacaoGuias/access/listarClassificacaoGuiaPlanos", {ID_CLASSIFICA_GUIA: idRegistro}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getClassificacaoGuiaProdutos(context, idRegistro) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("classificacaoGuias/access/listarClassificacaoGuiaProdutos", {ID_CLASSIFICA_GUIA: idRegistro}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },    
    deleteClassificacaoGuia(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("classificacaoGuias/access/excluir", {ID_CLASSIFICA_GUIA: dados.id, componente: dados.componente}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    postClassificacaoGuia(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("classificacaoGuias/access/salvar", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },    
    getClassGuiaQtdPrimeiro() {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.get("classificacaoGuias/access/obterClassGuiaQtdPrimeiro").then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};