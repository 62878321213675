import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

const state = {};

const getters = {};

const mutations = {};

const actions = {
    getLaudos(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentosFilaLaudo/access/lista", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getDiversosAgenda(context, idAgenda) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentosFilaLaudo/access/listarDiversosAgenda", {ID_AGENDA: idAgenda}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    associarLaudo(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentosFilaLaudo/access/associarLaudo", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    concluirComplemento(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentosFilaLaudo/access/concluirComplemento", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    naoExecutarLaudo(context, idAgendaExame) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentosFilaLaudo/access/naoExecutarLaudo", {ID_AGENDA_EXAME: idAgendaExame}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    salvarLaudoImportacao(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentosFilaLaudo/access/importarArquivoLaudo", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }, 
    enviarAcessoLaudoEmail(context, idPacienteLaudo) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentosFilaLaudo/access/enviarAcessoLaudoEmail", {ID_PACIENTE_LAUDO: idPacienteLaudo}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }, 
    esqueciSenhaPacienteLaudo(context, idPacienteLaudo) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentosFilaLaudo/access/esqueciSenhaPaciente", {ID_PACIENTE_LAUDO: idPacienteLaudo}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }, 
    listarEnviosLaudo(context, idPacienteLaudo) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentosFilaLaudo/access/listarEnviosLaudo", {ID_PACIENTE_LAUDO: idPacienteLaudo}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};