const state = {
  chamadosTV: []
};

const getters = {
  chamadosTV(state) {
    return state.chamadosTV;
  }
};

const mutations = {
  upadateChamadosTV(store, chamadosTV) {
    store.chamadosTV = chamadosTV;
  }
};

const actions = {
  upadateChamadosTV({ commit }, chamadosTV) {
    commit('upadateChamadosTV', chamadosTV);
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};