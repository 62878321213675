<template>
  <button 
    type="button" 
    class="btn" 
    :class="{
      'btn-sm': size == 'sm', 
      'btn-md': size == 'md', 
      'btn-lg': size == 'lg', 
      'btn-light': color == 'light', 
      'btn-primary': color == 'primary', 
      'btn-secondary': color == 'secondary', 
      'btn-success': color == 'success', 
      'btn-info': color == 'info', 
      'btn-warning': color == 'warning', 
      'btn-danger': color == 'danger', 
      'btn-dark': color == 'dark'
    }" 
    :disabled="(disabled) || (isLoading)" 
    :style="{'width': block ? '100%' : ''}"
  >
    <span class="indicator-label">
      {{ label }}
      <span 
        v-if="isLoading" 
        class="spinner-border spinner-border-sm align-middle ms-2"
      ></span>
    </span>
  </button>
</template>

<script>
export default {
  name: "ButtonPadrao",
  props: ["label", "disabled", "isLoading", "size", "color", "block"],
  methods: {
  }
}
</script>

<style>

</style>