import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

const state = {};

const getters = {};

const mutations = {};

const actions = {
    getContasPaciente(context, dados) {
        if((!dados.pagina) || (!dados.quantidadePorPagina)) {
            dados.pagina = 1;
            dados.quantidadePorPagina = 25;
        }
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("contasPaciente/access/listar", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getContasPacienteItens(context, idConta) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("contasPaciente/access/listarItensConta", {ID_PACIENTE_CONTA: idConta}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getContasPacienteProcedimentos(context, idConta) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("contasPaciente/access/listarProcedimentosConta", {ID_PACIENTE_CONTA: idConta}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getContasPacienteStaff(context, idConta) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("contasPaciente/access/listarStaffMedicoConta", {ID_PACIENTE_CONTA: idConta}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getContasPacienteItemStaffs(context, idFaturamento) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("contasPaciente/access/listarStaffItemConta", {ID_FATURAMENTO: idFaturamento}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getContaPaciente(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("contasPaciente/access/obter", { ID_PACIENTE_CONTA: dados.id, componente: dados.componente }).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getItemProduto(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("contasPaciente/access/obterProduto", { ID_PRODUTO_MOVIMENTACAO: dados.id, componente: dados.componente }).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getItemProcedimento(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("contasPaciente/access/obterProcedimento", { ID_FATURAMENTO: dados.id, componente: dados.componente }).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getItemProcedimentoStaff(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("contasPaciente/access/obterStaff", { ID_FATURAMENTO_STAFF_MEDICO: dados.id, componente: dados.componente }).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    calcularPrecoItem(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentos/access/calcularPrecoItem", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }, 
    listaGuias(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentos/access/listaGuiasTiss", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }, 
    calcularPrecoStaff(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("contasPaciente/access/calcularPrecoStaff", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }, 
    salvarConta(context, dados) {
      return new Promise(resolve => {
          if(JwtService.getToken()) {
              ApiService.setHeader();
              ApiService.post("contasPaciente/access/salvar", dados).then(({ data }) => {
                  resolve(data);
              });
          }
      });
    }, 
    salvarStatus(context, dados) {
      return new Promise(resolve => {
          if(JwtService.getToken()) {
              ApiService.setHeader();
              ApiService.post("contasPaciente/access/salvarStatusConta", dados).then(({ data }) => {
                  resolve(data);
              });
          }
      });
    },
    salvarContaItem(context, dados) {
        return new Promise(resolve => {
            if(JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("contasPaciente/access/salvarItem", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
      },
      salvarContaItemStaff(context, dados) {
          return new Promise(resolve => {
              if(JwtService.getToken()) {
                  ApiService.setHeader();
                  ApiService.post("contasPaciente/access/salvarStaff", dados).then(({ data }) => {
                      resolve(data);
                  });
              }
          });
        },
        deleteConta(context, dados) {
            return new Promise(resolve => {
                if (JwtService.getToken()) {
                    ApiService.setHeader();
                    ApiService.post("contasPaciente/access/excluir", { ID_PACIENTE_CONTA: dados.ID_PACIENTE_CONTA, componente: dados.componente }).then(({ data }) => {
                        resolve(data);
                    });
                }
            });
        },
        deleteContaItem(context, dados) {
            return new Promise(resolve => {
                if (JwtService.getToken()) {
                    ApiService.setHeader();
                    ApiService.post("contasPaciente/access/excluirItem", dados).then(({ data }) => {
                        resolve(data);
                    });
                }
            });
        },
        deleteContaItemStaff(context, dados) {
            return new Promise(resolve => {
                if (JwtService.getToken()) {
                    ApiService.setHeader();
                    ApiService.post("contasPaciente/access/excluirItemStaffMedico", { ID_FATURAMENTO_STAFF_MEDICO: dados.id, componente: dados.componente }).then(({ data }) => {
                        resolve(data);
                    });
                }
            });
        }, 
        refazInformacoesTiss(context, idConta) {
            return new Promise(resolve => {
                if (JwtService.getToken()) {
                    ApiService.setHeader();
                    ApiService.post("contasPaciente/access/refazerInfoTiss", {ID_PACIENTE_CONTA: idConta}).then(({ data }) => {
                        resolve(data);
                    });
                }
            });
        }, 
        getValorPagoConta(context, idConta) {
            return new Promise(resolve => {
                if (JwtService.getToken()) {
                    ApiService.setHeader();
                    ApiService.post("contasPaciente/access/obterContaPagamento", {ID_PACIENTE_CONTA: idConta}).then(({ data }) => {
                        resolve(data);
                    });
                }
            });
        }
};

export default {
    state,
    actions,
    mutations,
    getters
};