<template>
  <div class="fv-row fv-plugins-icon-container" :class="{'mb-7': !nooutline}">
    <div v-if="!simple">
      <label v-if="!nolabel" class="fs-6 fw-semibold form-label mt-3" style="margin-bottom: 12px;">
        <span :class="{'required': required}">{{ (label) ? label : 'Foto' }}</span>
      </label>
      <div :class="{'mt-1': !nooutline}">
        <div class="image-input image-input-placeholder image-input-empty" :class="{'image-input-outline': !nooutline}">
          <div 
            class="image-input-wrapper" 
            :class="{
              'w-100px': !small, 
              'h-100px': !small, 
              'w-50px': small, 
              'h-50px': small
            }"
            :style="{'background-image': 'url(' + retornaValor() + ')' }" 
            style="background-size: contain; background-position: center; cursor: pointer;" 
            @click="zoomFoto"
          ></div>
          <label v-if="!disabled" class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" aria-label="Change avatar" data-kt-initialized="1" @click="tirarFoto">
            <i class="bi bi-pencil-fill fs-7"></i>
          </label>
          <span class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="cancel" data-bs-toggle="tooltip" aria-label="Cancel avatar" data-kt-initialized="1">
            <i class="bi bi-x fs-2"></i>
          </span>
          <span class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="remove" data-bs-toggle="tooltip" aria-label="Remove avatar" data-kt-initialized="1">
            <i class="bi bi-x fs-2"></i>
          </span>
        </div>
      </div>
    </div>
    <div v-if="simple">
      <a class="dropzone-select btn btn-sm btn-primary me-2 dz-clickable" @click="tirarFoto" style="margin: 0;">
        Tirar Foto
      </a>
    </div>
    <div class="fv-plugins-message-container invalid-feedback"></div>
    <ModalBase ref="modalBase">
      <div class="text-center mb-13" style="margin-bottom: 20px !important;">
        <h1 class="mb-3">Imagem</h1>
      </div>
      <img v-if="retornaValor()" :src="retornaValor()" style="width: 100%;" />
    </ModalBase>
    <ModalBase ref="modalBaseTirarFoto" @toggleModal="fechouModalTirarFoto">
      <div class="text-center mb-13" style="margin-bottom: 20px !important;">
        <h1 class="mb-3">Tirar Foto</h1>
      </div>
      <div>
        <video ref="videoComponente" style="width: 100%;"></video>
        <canvas ref="canvasComponente" style="width: 100%; display: none;"></canvas>
      </div>
      <div class="row" style="margin-top: 16px;">
        <div class="col-md-6" style="cursor: pointer;">
          <a href="javascript:;" class="file btn btn-lg btn-primary" style="position: relative; overflow: hidden;">
            Escolher Arquivo
            <input type="file" name="file" @change="lerImagem" style="position: absolute; font-size: 50px; opacity: 0; right: 0; top: 0;cursor: pointer;" />
          </a>
        </div>
        <div class="col-md-6" style="text-align: right;">
          <a href="javascript:;" class="file btn btn-lg btn-primary" style="position: relative; overflow: hidden;" @click="tirarFotoCamera">
            Tirar Foto
          </a>
        </div>
      </div>
    </ModalBase>
  </div>
</template>

<script>
import ModalBase from '@/components/modals/ModalBase.vue';

export default {
  name: "InputFoto",
  components: {
    ModalBase
  },
  data() {
    return {
      mediaStream: null
    };
  },
  computed: {
    valor: {
      get: function() {
        return this.value ? this.value : this.modelValue;
      },
      set: function(newValue) {
        this.$emit("update:modelValue", newValue);
      }
    }
  },
  props: [
    "modelValue", 
    "label", 
    "disabled", 
    "required", 
    "simple", 
    "nolabel", 
    "nooutline", 
    "value", 
    "valorPuro",
    "small", 
    "abreExternamente"
  ],
  methods: {
    lerImagem(e) {
      if(e.target.files.length) {
        let selectedImage = e.target.files[0];
        let reader = new FileReader();
        reader.onload = (event) => {
          if(this.simple) {
            this.$emit("update:modelValue", {
              nome: "imagemDaCamera.jpg",
              arquivo: event.target.result,
              size: selectedImage.size,
              type: selectedImage.type
            });
          } else {
            this.$emit("update:modelValue", event.target.result.replace("data:image/jpeg;base64,", ""));
          }
          
          this.$refs.modalBaseTirarFoto.toggleModal();
        };
        reader.readAsDataURL(selectedImage);
      }
    },
    zoomFoto() {
      this.$emit("zoomFoto", this.retornaValor());

      if(!this.abreExternamente) {
        this.$refs.modalBase.toggleModal();
      }
    },
    async tirarFoto() {
      this.$refs.modalBaseTirarFoto.toggleModal();

      try {
        this.mediaStream = await navigator.mediaDevices.getUserMedia({video: true});
        this.$refs.videoComponente.srcObject = this.mediaStream;
        this.$refs.videoComponente.play();
      } catch (error) {
        console.log('Não há permissões para acessar a webcam');
      }
    },
    fechouModalTirarFoto(showModal) {
      if(!showModal) {
        this.$refs.videoComponente.pause();
        this.mediaStream.getTracks().forEach(track => track.stop());
        this.$nextTick(() => {
          this.$refs.videoComponente.srcObject = null;
        });
      }
    },
    tirarFotoCamera() {
      this.$refs.canvasComponente.height = this.$refs.videoComponente.videoHeight;
      this.$refs.canvasComponente.width  = this.$refs.videoComponente.videoWidth;
      let context = this.$refs.canvasComponente.getContext('2d');
      context.drawImage(this.$refs.videoComponente, 0, 0);
      if(this.simple) {
        let blob = this.dataURLtoBlob(this.$refs.canvasComponente.toDataURL('image/jpeg'));
        this.$emit("update:modelValue", {
          nome: "imagemDaCamera.jpg",
          arquivo: this.$refs.canvasComponente.toDataURL('image/jpeg'),
          size: blob.size,
          type: "image/jpeg"
        });
      } else {
        this.$emit("update:modelValue", this.$refs.canvasComponente.toDataURL('image/jpeg').replace("data:image/jpeg;base64,", ""));
      }
      this.$refs.modalBaseTirarFoto.toggleModal();
    },
    dataURLtoBlob(dataURL) {
      let binary = atob(dataURL.split(',')[1]);
      let array = [];
      for(var i = 0; i < binary.length; i++) {
          array.push(binary.charCodeAt(i));
      }
      return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
    },
    retornaValor() {
      if(this.valor) {
        if((this.valor.indexOf("data:image") >= 0) || (this.valorPuro)) {
          return this.valor;
        } else {
          return "data:image/jpeg;base64," + this.valor;
        }
      } else {
        return "";
      }
    }
  }
};
</script>

<style scoped>
  .image-input-placeholder {
    background-image: url('../../../public/assets/media/svg/files/blank-image.svg');
  } 
  
  [data-theme="dark"] .image-input-placeholder {
    background-image: url('../../../public/assets/media/svg/files/blank-image-dark.svg');
  }
</style>