const state = {};

const getters = {};

const mutations = {};

const actions = {
  textToSpeech(context, texto) {
    return new Promise(resolve => {
      if ('speechSynthesis' in window) {
        let msg = new SpeechSynthesisUtterance();
        let voices = speechSynthesis.getVoices();
        voices.forEach((voice, index) => {
          if(voice.lang == "pt-BR") {
            msg.voice = voices[index];
          }
        });
        msg.text = texto;
        msg.lang = "pt-BR";
        speechSynthesis.speak(msg);
        
        resolve(true);
      } else {
        resolve(false);
      }
    });
  },
  sleepTime(context, time) {
    return new Promise(resolve => setTimeout(resolve, time));
  }, 
  globalTest() {
    return "123";
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};