import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

const state = {};

const getters = {};

const mutations = {};

const actions = {
    getSolicitacoesProcedimento(context, dados) {
        return new Promise(resolve => {
            if(JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("solicitacaoExames/access/listar", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }, 
    salvarSolicitacaoProcedimento(context, dados) {
        return new Promise(resolve => {
            if(JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("solicitacaoExames/access/salvar", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }
};

export default {
  state,
  actions,
  mutations,
  getters
};