import { createWebHistory, createRouter } from "vue-router";

export default createRouter({
    history: createWebHistory(),
    routes: [{
        path: "/",
        redirect: "/dashboard",
    }, {
        path: "/dashboard",
        name: "dashboard",
        component: () => import(/* webpackChunkName: "dashboard" */ "@/pages/DashboardAnalytics.vue")
    }, {
        path: "/totemFuncionario",
        name: "totemFuncionario",
        meta: {
            title: "Totem manipulado pelo Funcionário"
        },
        component: () => import(/* webpackChunkName: "totemFuncionario" */ "@/pages/TotemFuncionario.vue")
    }, {
        path: "/totemPaciente",
        name: "totemPaciente",
        meta: {
            title: "Totem manipulado pelo Paciente"
        },
        component: () => import(/* webpackChunkName: "totemPaciente" */ "@/pages/TotemPaciente.vue")
    }, {
        path: "/chamadaNaTV",
        name: "chamadaNaTV",
        meta: {
            title: "Chamada na TV"
        },
        component: () => import(/* webpackChunkName: "chamadaNaTV" */ "@/pages/ChamadaNaTV.vue")
    }, {
        path: "/agenda",
        name: "agenda",
        component: () => import(/* webpackChunkName: "agenda" */ "@/pages/AgendaSpirit.vue")
    }, {
        path: "/filaPep",
        name: "filaPep",
        meta: {
            title: "Fila do Prontuário Eletrônico do Paciente (PEP)"
        },
        component: () => import(/* webpackChunkName: "filaPep" */ "@/pages/FilaPEP.vue")
    }, {
        path: "/filaLaudo",
        name: "filaLaudo",
        meta: {
            title: "Fila do Prontuário Eletrônico do Paciente (PEP)"
        },
        component: () => import(/* webpackChunkName: "filaLaudo" */ "@/pages/FilaPEP.vue")
    }, {
        path: "/repasse",
        name: "repasse",
        meta: {
            title: "Repasse Médico"
        },
        component: () => import(/* webpackChunkName: "repasse" */ "@/pages/RepasseMedico.vue")
    }, {
        path: "/atencaoContinua", 
        name: "atencaoContinua", 
        meta: {
            title: "Atenção Contínua"
        }, 
        component: () => import(/* webpackChunkName: "repasse" */ "@/pages/AgendaRetorno.vue")
    }, {
        path: "/solicitacaoProcedimento", 
        name: "solicitacaoProcedimento", 
        meta: {
            title: "Solicitação de Procedimento"
        }, 
        component: () => import(/* webpackChunkName: "repasse" */ "@/pages/SolicitacaoProcedimento.vue")
    }, {
        path: "/pep/:id",
        name: "pep",
        meta: {
            title: "Prontuário Eletrônico do Paciente (PEP)"
        },
        component: () => import(/* webpackChunkName: "pep" */ "@/pages/ProntuarioEletronico.vue")
    }, {
        path: "/pep/:id/:idAgenda",
        name: "pepComAgenda",
        meta: {
            title: "Prontuário Eletrônico do Paciente (PEP)"
        },
        component: () => import(/* webpackChunkName: "pepComAgenda" */ "@/pages/ProntuarioEletronico.vue")
    }, {
        path: "/pep/:id/:idAgenda/:idAgendaExame/:idProduto",
        name: "pepLaudo",
        meta: {
            title: "Prontuário Eletrônico do Paciente (PEP)"
        },
        component: () => import(/* webpackChunkName: "pepLaudo" */ "@/pages/ProntuarioEletronico.vue")
    }, {
        path: "/pep/:id/:idAgenda/:idAgendaExame/:idProduto/:idDiversos",
        name: "pepLaudoAlteracao",
        meta: {
            title: "Prontuário Eletrônico do Paciente (PEP)"
        },
        component: () => import(/* webpackChunkName: "pepLaudoAlteracao" */ "@/pages/ProntuarioEletronico.vue")
    }, {
        path: "/textos",
        name: "textos",
        component: () => import(/* webpackChunkName: "textos" */ "@/pages/CadTextos.vue")
    }, {
        path: "/texto",
        name: "textoInc",
        component: () => import(/* webpackChunkName: "textoInc" */ "@/pages/CadTexto.vue")
    }, {
        path: "/texto/:id",
        name: "textoAlt",
        component: () => import(/* webpackChunkName: "textoAlt" */ "@/pages/CadTexto.vue")
    }, {
        path: "/textosEmail",
        name: "textosEmail",
        component: () => import(/* webpackChunkName: "textosEmail" */ "@/pages/CadTextosEmail.vue")
    }, {
        path: "/textoEmail",
        name: "textoEmailInc",
        component: () => import(/* webpackChunkName: "textoEmailInc" */ "@/pages/CadTextoEmail.vue")
    }, {
        path: "/textoEmail/:id",
        name: "textoEmailAlt",
        component: () => import(/* webpackChunkName: "textoEmailAlt" */ "@/pages/CadTextoEmail.vue")
    }, {
        path: "/turnos",
        name: "turnos",
        component: () => import(/* webpackChunkName: "turnos" */ "@/pages/CadTurnos.vue")
    }, {
        path: "/turno",
        name: "turnoInc",
        component: () => import(/* webpackChunkName: "turnoInc" */ "@/pages/CadTurno.vue")
    }, {
        path: "/turno/:id",
        name: "turnoAlt",
        component: () => import(/* webpackChunkName: "turnoAlt" */ "@/pages/CadTurno.vue")
    }, {
        path: "/unidades",
        name: "unidades",
        component: () => import(/* webpackChunkName: "unidades" */ "@/pages/CadUnidades.vue")
    }, {
        path: "/unidade",
        name: "unidadeInc",
        component: () => import(/* webpackChunkName: "unidadeInc" */ "@/pages/CadUnidade.vue")
    }, {
        path: "/unidade/:id",
        name: "unidadeAlt",
        component: () => import(/* webpackChunkName: "unidadeAlt" */ "@/pages/CadUnidade.vue")
    }, {
        path: "/staffMedicos",
        name: "staffMedicos",
        meta: {
            title: "Inclusão Cadastro Staff Médico"
        },
        component: () => import(/* webpackChunkName: "staffMedicos" */ "@/pages/CadStaffMedicos.vue")
    }, {
        path: "/staffMedico",
        name: "staffMedicoInc",
        component: () => import(/* webpackChunkName: "staffMedicoInc" */ "@/pages/CadStaffMedico.vue")
    }, {
        path: "/staffMedico/:id",
        name: "staffMedicoAlt",
        meta: {
            title: "Alteração Cadastro Staff Médico"
        },
        component: () => import(/* webpackChunkName: "staffMedicoAlt" */ "@/pages/CadStaffMedico.vue")
    }, {
        path: "/staffMedicosCategorias",
        name: "staffMedicosCategorias",
        component: () => import(/* webpackChunkName: "staffMedicosCategorias" */ "@/pages/CadStaffMedicosCategorias.vue")
    }, {
        path: "/staffMedicoCategoria",
        name: "staffMedicoCategoriaInc",
        component: () => import(/* webpackChunkName: "staffMedicoCategoriaInc" */ "@/pages/CadStaffMedicoCategoria.vue")
    }, {
        path: "/staffMedicoCategoria/:id",
        name: "staffMedicoCategoriaAlt",
        component: () => import(/* webpackChunkName: "staffMedicoCategoriaAlt" */ "@/pages/CadStaffMedicoCategoria.vue")
    }, {
        path: "/contatosExternos",
        name: "contatosExternos",
        component: () => import(/* webpackChunkName: "contatosExternos" */ "@/pages/CadContatosExternos.vue")
    }, {
        path: "/contatoExterno",
        name: "contatoExternoInc",
        component: () => import(/* webpackChunkName: "contatoExternoInc" */ "@/pages/CadContatoExterno.vue")
    }, {
        path: "/contatoExterno/:id",
        name: "contatoExternoAlt",
        component: () => import(/* webpackChunkName: "contatoExternoAlt" */ "@/pages/CadContatoExterno.vue")
    }, {
        path: "/servicos",
        name: "servicos",
        component: () => import(/* webpackChunkName: "servicos" */ "@/pages/CadServicos.vue")
    }, {
        path: "/servico",
        name: "servicoInc",
        component: () => import(/* webpackChunkName: "servicoInc" */ "@/pages/CadServico.vue")
    }, {
        path: "/servico/:id",
        name: "servicoAlt",
        component: () => import(/* webpackChunkName: "servicoAlt" */ "@/pages/CadServico.vue")
    }, {
        path: "/procedimentos",
        name: "procedimentos",
        component: () => import(/* webpackChunkName: "procedimentos" */ "@/pages/CadProcedimentos.vue")
    }, {
        path: "/procedimento",
        name: "procedimentoInc",
        component: () => import(/* webpackChunkName: "procedimentoInc" */ "@/pages/CadProcedimento.vue")
    }, {
        path: "/procedimento/:id",
        name: "procedimentoAlt",
        component: () => import(/* webpackChunkName: "procedimentoAlt" */ "@/pages/CadProcedimento.vue")
    }, {
        path: "/produtos",
        name: "produtos",
        component: () => import(/* webpackChunkName: "produtos" */ "@/pages/CadProdutos.vue")
    }, {
        path: "/produto",
        name: "produtoInc",
        component: () => import(/* webpackChunkName: "produtoInc" */ "@/pages/CadProduto.vue")
    }, {
        path: "/produto/:id",
        name: "produtoAlt",
        component: () => import(/* webpackChunkName: "produtoAlt" */ "@/pages/CadProduto.vue")
    }, {
        path: "/pacientes",
        name: "pacientes",
        component: () => import(/* webpackChunkName: "pacientes" */ "@/pages/CadPacientes.vue")
    }, {
        path: "/paciente",
        name: "pacienteInc",
        component: () => import(/* webpackChunkName: "pacienteInc" */ "@/pages/CadPaciente.vue")
    }, {
        path: "/paciente/:id",
        name: "pacienteAlt",
        meta: {
            title: "Cadastro do Paciente"
        },
        component: () => import(/* webpackChunkName: "pacienteAlt" */ "@/pages/CadPaciente.vue")
    }, {
        path: "/convenios",
        name: "convenios",
        component: () => import(/* webpackChunkName: "convenios" */ "@/pages/CadConvenios.vue")
    }, {
        path: "/convenio",
        name: "convenioInc",
        component: () => import(/* webpackChunkName: "convenioInc" */ "@/pages/CadConvenio.vue")
    }, {
        path: "/convenio/:id",
        name: "convenioAlt",
        component: () => import(/* webpackChunkName: "convenioAlt" */ "@/pages/CadConvenio.vue")
    }, {
        path: "/usuarios",
        name: "usuarios",
        component: () => import(/* webpackChunkName: "usuarios" */ "@/pages/CadUsuarios.vue")
    }, {
        path: "/usuario",
        name: "usuarioInc",
        component: () => import(/* webpackChunkName: "usuarioInc" */ "@/pages/CadUsuario.vue")
    }, {
        path: "/usuario/:id",
        name: "usuarioAlt",
        meta: {
            title: "Alteração de Usuário"
        },
        component: () => import(/* webpackChunkName: "usuarioAlt" */ "@/pages/CadUsuario.vue")
    }, {
        path: "/perfil",
        name: "perfil",
        component: () => import(/* webpackChunkName: "perfil" */ "@/pages/UsuarioPerfil.vue")
    }, {
        path: "/locaisAtendimentoCategorias",
        name: "locaisAtendimentoCategorias",
        component: () => import(/* webpackChunkName: "locaisAtendimentoCategorias" */ "@/pages/CadLocaisAtendimentoCategorias.vue")
    }, {
        path: "/localAtendimentoCategoria",
        name: "localAtendimentoCategoriaInc",
        component: () => import(/* webpackChunkName: "localAtendimentoCategoriaInc" */ "@/pages/CadLocalAtendimentoCategoria.vue")
    }, {
        path: "/localAtendimentoCategoria/:id",
        name: "localAtendimentoCategoriaAlt",
        component: () => import(/* webpackChunkName: "localAtendimentoCategoriaAlt" */ "@/pages/CadLocalAtendimentoCategoria.vue")
    }, {
        path: "/categoriasTextos",
        name: "categoriasTextos",
        component: () => import(/* webpackChunkName: "categoriasTextos" */ "@/pages/CadCategoriasTextos.vue")
    }, {
        path: "/categoriaTexto",
        name: "categoriaTextoInc",
        component: () => import(/* webpackChunkName: "categoriaTextoInc" */ "@/pages/CadCategoriaTexto.vue")
    }, {
        path: "/categoriaTexto/:id",
        name: "categoriaTextoAlt",
        component: () => import(/* webpackChunkName: "categoriaTextoAlt" */ "@/pages/CadCategoriaTexto.vue")
    }, {
        path: "/configuracoes",
        name: "configuracoes",
        component: () => import(/* webpackChunkName: "configuracoes" */ "@/pages/ConfiguracoesSistema.vue")
    }, {
        path: "/redirect/:rota",
        name: "redirect",
        component: () => import(/* webpackChunkName: "redirect" */ "@/pages/ComponentRedirect.vue")
    }, {
        path: "/locais",
        name: "locais",
        component: () => import(/* webpackChunkName: "locais" */ "@/pages/CadLocais.vue")
    }, {
        path: "/local",
        name: "localInc",
        component: () => import(/* webpackChunkName: "localInc" */ "@/pages/CadLocal.vue")
    }, {
        path: "/local/:id",
        name: "localAlt",
        component: () => import(/* webpackChunkName: "localAlt" */ "@/pages/CadLocal.vue")
    }, {
        path: "/tabelasPrecos",
        name: "tabelasPrecos",
        component: () => import(/* webpackChunkName: "tabelasPrecos" */ "@/pages/CadTabelasPrecos.vue")
    }, {
        path: "/tabelaPreco",
        name: "tabelaPrecoInc",
        component: () => import(/* webpackChunkName: "tabelaPrecoInc" */ "@/pages/CadTabelaPreco.vue")
    }, {
        path: "/tabelaPreco/:id",
        name: "tabelaPrecoAlt",
        component: () => import(/* webpackChunkName: "tabelaPrecoAlt" */ "@/pages/CadTabelaPreco.vue")
    }, {
        path: "/classificacaoGuias",
        name: "classificacaoGuias",
        component: () => import(/* webpackChunkName: "classificacaoGuias" */ "@/pages/CadClassificacaoGuias.vue")
    }, {
        path: "/classificacaoGuia",
        name: "classificacaoGuiaInc",
        component: () => import(/* webpackChunkName: "classificacaoGuiaInc" */ "@/pages/CadClassificacaoGuia.vue")
    }, {
        path: "/classificacaoGuia/:id",
        name: "classificacaoGuiaAlt",
        component: () => import(/* webpackChunkName: "classificacaoGuiaAlt" */ "@/pages/CadClassificacaoGuia.vue")
    }, {
        path: "/classificacaoAtendimentos",
        name: "classificacaoAtendimentos",
        component: () => import(/* webpackChunkName: "classificacaoAtendimentos" */ "@/pages/CadClassificacaoAtendimentos.vue")
    }, {
        path: "/classificacaoAtendimento",
        name: "classificacaoAtendimentoInc",
        component: () => import(/* webpackChunkName: "classificacaoAtendimentoInc" */ "@/pages/CadClassificacaoAtendimento.vue")
    }, {
        path: "/classificacaoAtendimento/:id",
        name: "classificacaoAtendimentoAlt",
        component: () => import(/* webpackChunkName: "classificacaoAtendimentoAlt" */ "@/pages/CadClassificacaoAtendimento.vue")
    }, {
        path: "/contasPaciente",
        name: "contasPaciente",
        component: () => import(/* webpackChunkName: "contasPaciente" */ "@/pages/ContasPaciente.vue")
    }, {
        path: "/contasPaciente/:idAgenda",
        name: "contasPacienteAgenda",
        component: () => import(/* webpackChunkName: "contasPaciente" */ "@/pages/ContasPaciente.vue")
    }]
});