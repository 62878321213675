import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

const state = {};

const getters = {};

const mutations = {};

const actions = {
    getClassificacoesAtendimento(context, idConvenio) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("classificacaoAtendimentos/access/listarPorConvenio", { ID_CONVENIO: idConvenio }).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getClassificacoesAtendimentoBox(context, filtros) {
        filtros.componente = "SearchBoxClassificacoesAtendimento";
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("classificacaoAtendimentos/access/listaBox", filtros).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getClassificacaoAtendimentos(context, dados) {
        if((!dados.pagina) || (!dados.quantidadePorPagina)) {
            dados.pagina = 1;
            dados.quantidadePorPagina = 25;
        }
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("ClassificacaoAtendimentos/access/listar", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getClassificacaoAtendimento(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("ClassificacaoAtendimentos/access/obter", {ID_CLASSIFICA_ATENDIMENTO: dados.id, componente: dados.componente}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getClassificacaoAtClassGuia(context, idRegistro) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("ClassificacaoAtendimentos/access/listarClassificacaoAtClassGuia", {ID_CLASSIFICA_ATENDIMENTO: idRegistro}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },    
    deleteClassificacaoAtendimento(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("ClassificacaoAtendimentos/access/excluir", {ID_CLASSIFICA_ATENDIMENTO: dados.id, componente: dados.componente}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    postClassificacaoAtendimento(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("ClassificacaoAtendimentos/access/salvar", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },    
    getClassAtendQtdPrimeiro() {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.get("ClassificacaoAtendimentos/access/obterClassAtendQtdPrimeiro").then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};