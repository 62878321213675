import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

const state = {};

const getters = {};

const mutations = {};

const actions = {
    getProdutosCategoriasBox(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("produtosCategoria/access/listaBox", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};