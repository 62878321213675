import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

const state = {};

const getters = {};

const mutations = {};

const actions = {
    getContaPendencias(context, idConta) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("contasPacientePendencia/access/listarPendenciasConta", {ID_PACIENTE_CONTA: idConta}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getItemPendencia(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("contasPacientePendencia/access/obterPendenciaItem", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getItemPendenciaOcorrencias(context, idPendencia) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("contasPacientePendencia/access/listarOcorrenciasPendencia", {ID_PENDENCIA: idPendencia}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }, 
    salvarPendencia(context, dados) {
      return new Promise(resolve => {
          if(JwtService.getToken()) {
              ApiService.setHeader();
              ApiService.post("contasPacientePendencia/access/salvar", dados).then(({ data }) => {
                  resolve(data);
              });
          }
      });
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};