import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

const state = {};

const getters = {};

const mutations = {};

const actions = {
    getPermissoes() {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.get("security/access/listarPermissoes").then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getPermissao(context, permissao) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("security/access/obterPermissao", permissao).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getPermissoesLista(context, permissoes) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("security/access/obterPermissoes", permissoes).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};